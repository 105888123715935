import { PATHS } from "@/constants/paths";
import { UserInfo } from "@/types/userinfo";
import { Navigate, useRouteLoaderData } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const userinfo = useRouteLoaderData("main") as UserInfo;
  if (!userinfo?.isLoggedIn) {
    return <Navigate to={PATHS.LOGIND} replace />;
  }
  return children;
}
