import "../../assets/scss/index.scss";
import { Outlet, useLoaderData } from "react-router-dom";

import Footer from "@/components/footer/Footer";
import Header from "@/components/header/Header";
import { UserInfo } from "@/types/userinfo";
import React, { useMemo } from "react";
import { UserContext } from "@/contexts/userContext";

export default function Main() {
  const user = useLoaderData() as UserInfo;
  const userinfo = useMemo(() => {
    return user;
  }, [user.isLoggedIn])

  return (
    <>
      <header className={"header"}>
        <a href="#main-content" className="skipnav">
          Gå til sidens indhold
        </a>
        <Header userinfo={userinfo} />
      </header>
      <div className={"container page-container"}>
        <main className="content" id="main-content">
          <UserContext.Provider value={userinfo}>
            <Outlet />
          </UserContext.Provider>
        </main>
      </div>
      <Footer />
    </>
  );
}
