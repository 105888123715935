import { PATHS } from "@/constants/paths";
import IconMenu from "dkfds/src/img/svg-icons/menu.svg?react";
import Nav from "@/components/nav/Nav";
import { Link } from "react-router-dom";
import MitUnilogin from "../logo/MitUnilogin";
import { UserInfo } from "@/types/userinfo";
import UserView from "./UserView";
import SolutionInfo from "./SolutionInfo";
import dkfds from "dkfds";
import { useEffect } from "react";

export default function Header({ userinfo }: { readonly userinfo?: UserInfo }) {
  const navigation = new dkfds.Navigation();
  useEffect(() => {
    navigation.init();
  }, []);

  return (
    <>
      <div className="portal-header portal-header-compact">
        <div className="container portal-header-inner">
          <Link to={PATHS.FRONT} aria-label="STIL logo" className="logo mx-0">
            <span className="d-none d-print-block">STIL: MitUnilogin</span>
          </Link>
          <button className="function-link button-menu-open js-menu-open ml-auto d-print-none" aria-haspopup="dialog">
            <IconMenu className={"icon-svg"} />
            Menu
          </button>
          {userinfo?.isLoggedIn && <UserView userinfo={userinfo} />}
        </div>
        <div className="solution-header">
          <div className="container solution-header-inner">
            <div className="solution-heading">
              <Link to={PATHS.FRONT}>
                <MitUnilogin />
              </Link>
            </div>
            <SolutionInfo />
          </div>
        </div>
      </div>

      <div className="overlay"></div>
      <Nav userinfo={userinfo} />
    </>
  );
}
