import { RouteObject, createBrowserRouter } from "react-router-dom";
import { PATHS } from "@/constants/paths";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import Main from "@/views/app/Main";
import { getUserInfo } from "@/services/UserinfoService";
import AktoerOverblik from "@/views/aktoeroverblik/AktoerOverblik";
import Oversigt from "@/views/oversigt/Oversigt";
import LoginScreen from "@/views/login/LoginScreen";

export default function getRouter() {
  return createBrowserRouter(routes);
}

export const routes: RouteObject[] = [
  {
    path: PATHS.FRONT,
    element: <Main />,
    id: "main",
    loader: async () => await getUserInfo(),
    children: [
      {
        path: PATHS.LOGIND,
        element: <LoginScreen />,
      },
      {
        path: PATHS.FRONT,
        element: (
          <ProtectedRoute>
            <Oversigt />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.MIN_SKOLE,
        element: (
          <ProtectedRoute>
            <AktoerOverblik />
          </ProtectedRoute>
        ),
      },
    ],
  },
];
