import { AktoerDto } from "@/types/aktoerDto";
import IconOpenInNew from "dkfds/src/img/svg-icons/open-in-new.svg?react";

export default function AktoerCard({ aktoer }: { readonly aktoer: AktoerDto }) {
  const getContact = () => {
    return (
      aktoer.institution.registerType === "INSTITUTIONS_REGISTER" && (
        <a
          className="icon-link function-link"
          target={"_blank"}
          rel={"noreferrer"}
          href={"https://data.stil.dk/InstregV2/VisInstitution.aspx?InstNr=" + aktoer.institution.institutionId}
        >
          Kontakt institution
          <IconOpenInNew className={"icon-svg"} title={"ikon åben i nyt vindue"} />
        </a>
      )
    );
  };

  const getDetails = () => {
    if (aktoer.aktoerType === "Elev") {
      return <p>Du hører til {aktoer.elevHovedgruppeNavn}</p>;
    }
    if (aktoer.aktoerType === "Forælder eller kontaktperson") {
      return <p>Du er kontakt til {kontaktpersonFullname}</p>;
    }
  };

  const kontaktpersonFullname = `${aktoer.elevForKontaktperson?.fornavn} ${aktoer.elevForKontaktperson?.efternavn}`;

  const fullname = (
    <h2 className="displayheading-2">
      {aktoer.fornavn} {aktoer.efternavn}
    </h2>
  );
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="header-title">{aktoer.institution.navn}</h3>
        <p className="sub-header">{aktoer.aktoerType}</p>
      </div>
      <div className="card-content px-5 py-4 soft-blue aktoer-content ">
        {fullname}
        {getDetails()}
      </div>
      <div className="card-text">{getContact()}</div>
    </div>
  );
}
