import "../../assets/scss/index.scss";
import React from "react";
import { RouterProvider } from "react-router-dom";
import getRouter from "@/router/router";

export default function App() {
  return (
      <RouterProvider router={getRouter()} />
  );
}
