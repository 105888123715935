import { NAVIGATION_PATHS } from "@/constants/paths";
import NavOption from "./NavOption";
import { useLocation } from "react-router-dom";
import UserView from "../header/UserView";
import { UserInfo } from "@/types/userinfo";
import SolutionInfo from "../header/SolutionInfo";
import LukIkon from "dkfds/src/img/svg-icons/close.svg?react";

export default function Nav({ userinfo }: { readonly userinfo?: UserInfo }) {
  const { pathname } = useLocation();
  return (
    <nav className={"nav"}>
      <button className="function-link button-menu-close js-menu-close" aria-label="Luk menu">
        <LukIkon />
        Luk
      </button>
      {userinfo.isLoggedIn && (
        <div className="navbar navbar-primary">
          <div className="navbar-inner container">
            <ul className="nav-primary">
              {NAVIGATION_PATHS.map((path) => (
                <NavOption key={`nav${path.path}`} to={path.path} text={path.text} current={pathname === path.path} />
              ))}
            </ul>
          </div>
        </div>
      )}
      <UserView userinfo={userinfo} mobileView={true} />
      <SolutionInfo mobileView={true} />
    </nav>
  );
}
