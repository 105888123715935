import { AktoerlisteDto } from "@/types/aktoerlisteDto";
import AktoerCard from "../cards/AktoerCard";

export default function Aktoerliste({ aktoerliste }: { readonly aktoerliste: AktoerlisteDto }) {
  return (
    <div className={"gridcontainer"}>
        {aktoerliste.aktoerer.map((aktoer) => {
          return (
            <AktoerCard aktoer={aktoer} key={aktoer.aktoerUuid} />
          );
        })}
    </div>
  );
}
