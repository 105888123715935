export const PATHS = {
  FRONT: "/",
  MIN_SKOLE: "/min-skole",
  LOGIN_PATH: "/oauth2/authorization/mituniloginclient",
  LOGIND: "/logind",
  LOGOUT_PATH: "/logout",
};

export type NavigationPaths = {
  path: string;
  text: string;
  description: string;
  hideOnIndex?: boolean;
};

export const NAVIGATION_PATHS: NavigationPaths[] = [
  {
    path: PATHS.FRONT,
    text: "Oversigt",
    description: "",
    hideOnIndex: true,
  },
  {
    path: PATHS.MIN_SKOLE,
    text: "Min skole",
    description: "Her kan du se alle de institutioner du går på.",
  },
];

export const API_PATHS = {
  USERINFO_PATH: "api/userinfo",
  CSRF_TOKEN_PATH: "api/csrftoken",
};
