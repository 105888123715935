import IconOpenInNew from "dkfds/src/img/svg-icons/open-in-new.svg?react";
type SolutionInfoProps = { readonly mobileView?: boolean };

export default function SolutionInfo({ mobileView = false }: SolutionInfoProps) {
  return (
    <div className={mobileView ? "solution-info-mobile" : "solution-info"}>
      <p>
        <strong className="authority-name">Styrelsen for IT og Læring</strong>
      </p>
      <p>
        Support: <a href="tel:+4535878550">35 87 85 50</a>
        <span className="d-block d-lg-inline-block d-print-none mt-2 mt-lg-0">
          <span className="d-none d-lg-inline-block mr-2">· </span>
          <a href={"https://www.stil.dk/support"} className="icon-link function-link">
            Kontakt
            <IconOpenInNew className={"icon-svg"} />
          </a>
        </span>
      </p>
    </div>
  );
}
