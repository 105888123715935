import LinkCard from "@/components/cards/LinkCard";
import HandWave from "@/components/emoji/HandWave";
import MitUnilogin from "@/components/logo/MitUnilogin";
import { NAVIGATION_PATHS } from "@/constants/paths";
import { useRouteLoaderData } from "react-router-dom";
import { UserInfo } from "@/types/userinfo";

export default function Oversigt() {
  const { aktoerOverblik } = useRouteLoaderData("main") as UserInfo;

  return (
    <>
      <h1>
        Hej {aktoerOverblik.navn}, du er logget på <MitUnilogin /> <HandWave />
      </h1>
      <p role="heading" aria-level="2">Her kan du se de oplysninger, som vi har om dig.</p>
      <div className="row">
        {NAVIGATION_PATHS.filter((nav) => !nav.hideOnIndex).map((path) => {
          return (
            <div className="col-12 col-sm-6 col-md-4 mb-6" key={path.path}>
              <LinkCard overskrift={path.text} beskrivelse={path.description} path={path.path} />
            </div>
          );
        })}
      </div>
    </>
  );
}
