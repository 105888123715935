import { Link } from 'react-router-dom';

export default function LinkCard({overskrift, beskrivelse, path}) {
  return (
    <div className="card">
      <div className="card-header">
        <h2 className="header-title">{overskrift}</h2>
      </div>

      <div className="card-text">
        <p>{beskrivelse}</p>
      </div>

      <div className="card-footer card-action">
        <div className="action-links">
          <Link to={path}>{overskrift}</Link>
        </div>
      </div>
    </div>
  );
}
